import { Directive, forwardRef, AfterViewInit, OnDestroy, Input, Host, ElementRef, NgZone, OnInit, ChangeDetectorRef } from '@angular/core';
import { ValidatorFn, ValidationErrors } from '@angular/forms';
import { TranslationService } from '@spartacus/core';
import { InputComponent } from '@util/components/input/input.component';
import { getMillisecondsOfUnits } from '@util/functions/date';
import { coerceBoolean } from '@util/functions/objects';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';



@Directive({
  selector: '[appInputLateDateValidator]',
  inputs: ['appInputLateDateValidator.useTranslationKey', 'appInputLateDateValidator.trigger'],
  exportAs: 'appInputLateDateValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputLateDateValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)},
  ]
})
export class InputLateDateValidatorDirective extends BaseValidatorDirective implements OnInit, AfterViewInit, OnDestroy {

  // must be the selector infront of the dot
  protected override _thisSelector = 'appInputLateDateValidator';

  protected override translationKey = 'validation.input.appInputLateDateValidator';

  private _daysOffset: number = 0;
  private _overrideMin: boolean;

  private intersectionObserverOptions = {
    root: document.documentElement,
  };

  private intersectionObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      // is at least partially visible on screen
      if (entry.intersectionRatio > 0) {
        const minDate = this.getMinDate();
        this.overrideComponentMin(minDate);
        this.cdr.markForCheck();
      }
    });
  }, this.intersectionObserverOptions);

  get daysOffset(): number {
    return this._daysOffset;
  }

  @Input()
  set daysOffset(value: number) {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    this._daysOffset = value;
  }

  get overrideMin(): boolean {
    return this._overrideMin;
  }

  @Input()
  set overrideMin(value: boolean) {
    this._overrideMin = coerceBoolean(value);
  }

  constructor(
    @Host() host: BaseFormComponent,
    utilInteroperationalFormGroupService: UtilOmnipresentFormGroupService,
    translationService: TranslationService,
    ngZone: NgZone,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    super(host, utilInteroperationalFormGroupService, translationService, ngZone);
  }

  override ngOnInit() {
    super.ngOnInit();
    const hostElement = this.elementRef?.nativeElement as HTMLElement;
    this.intersectionObserver.observe(hostElement);

  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.host?.formControl?.addValidators(this.validator);
    this.host?.formControl?.updateValueAndValidity();
    this.host?.formControl?.markAsPristine();
    this.host?.formControl?.markAsUntouched();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.intersectionObserver?.disconnect();
  }

  validator: ValidatorFn = (control) => {

    const errors: ValidationErrors = {'appInputLateDateValidator': this.translation};

    const value = control?.value;

    let isOK = true;

    const minDate = this.getMinDate();

    if (value && this.host.type === 'date') {
      const newDate = new Date(value);
      newDate.setHours(12, 0, 0, 0);

      this.overrideComponentMin(minDate);

      if (minDate.getTime() > newDate.getTime()) {
        isOK = false;
      }
    }

    return value ? isOK ? null : errors : null;
  };

  private getMinDate(): Date {
    let minDate = new Date();
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    minDate.setHours(12, 0, 0, 0);

    if (this.daysOffset !== 0) {
      const extra = this.daysOffset * getMillisecondsOfUnits('1d');
      minDate = new Date(minDate.getTime() + extra);
    }

    return minDate;
  }

  private overrideComponentMin(minDate: Date) {
    if (this.overrideMin && this.host) {
      (this.host as unknown as {min: string}).min = minDate.toISOString();
    }
  }

}
